import { useEffect, createRef, FunctionComponent, useMemo } from 'react';
import { navigate } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { appendParamsCurrentPage, canUseDOM } from '@utils';
import { trackPromotionCard, trackCarDealCardImpressionEcom, trackCarDealCardClickEcom } from './tracking';
import Card from 'react-bootstrap/Card';
import { PromotionCardProps } from './interface';
import { WEB_BLOG_LINK } from '@config';

export const PromotionCard: FunctionComponent<PromotionCardProps> = (props: PromotionCardProps) => {
  const PromotionCardRef = createRef<HTMLDivElement>();
  useEffect(() => {
    const promotionBoxRef = document.getElementById('promotion-box');
    if (!promotionBoxRef) return;

    const PromotionCardObserver = new IntersectionObserver(
      (resp) => {
        if (resp[0].isIntersecting && resp[0].intersectionRatio === 1) {
          trackCarDealCardImpressionEcom({
            promotionID: props.promotionName,
            promotionName: props.promotionName,
            position: props.cardPosition,
            promotionTitle: props.promotionTitle
          });
          PromotionCardObserver.disconnect();
        }
      },
      { root: promotionBoxRef.children[0], threshold: 1 }
    );
    PromotionCardRef.current && PromotionCardObserver.observe(PromotionCardRef.current);
  }, []);

  const getPromotionPageURL = () => {
    const promotionPageURL = `${props.prefix}/${props.path}`;
    const url = appendParamsCurrentPage(promotionPageURL);
    return url;
  };
  const imageData = useMemo(() => {
    return getImage(props.imgSrc);
  }, [props.imgSrc]);

  return (
    <Card className="card--promo shadow" ref={PromotionCardRef}>
      <div
        onClick={(e) => {
          e.preventDefault();
          trackPromotionCard(e, props.promotionName);
          trackCarDealCardClickEcom({
            promotionID: props.promotionName,
            promotionName: props.promotionName,
            position: props.cardPosition,
            promotionTitle: props.promotionTitle
          });
          if (canUseDOM()) {
            const host = props.redirect_to_blog ? WEB_BLOG_LINK : window.location.origin;
            window.location.href = `${host}/${getPromotionPageURL()}`;
            return;
          }
          if (!props.redirect_to_blog) navigate(`/${getPromotionPageURL()}`);
        }}
      >
        {imageData && <GatsbyImage image={imageData} alt={props.path} />}
      </div>
    </Card>
  );
};
