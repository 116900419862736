import { useState, useEffect, createRef, FunctionComponent } from 'react';
import LazyLoad from 'react-lazyload';
import { PageSection } from '@utils';
import { trackAboutDrivehubSeen, trackAboutDrivehubExpand, AboutDrivehubExpand } from './tracking';
import './style.scss';
interface ValuePropsProps {
  sectionName: PageSection;
}
export const ValueProps: FunctionComponent<ValuePropsProps> = ({ sectionName }) => {
  const [toggle, setToggle] = useState(false);
  const aboutDrivehubBoxRef = createRef<HTMLDivElement>();
  const handleOnClick = () => {
    setToggle(!toggle);
    trackAboutDrivehubExpand(!toggle === true ? AboutDrivehubExpand.Expand : AboutDrivehubExpand.Hide, sectionName);
  };
  useEffect(() => {
    const aboutDrivehubObserver = new IntersectionObserver((resp) => {
      if (resp[0].isIntersecting) {
        trackAboutDrivehubSeen(sectionName);
        aboutDrivehubObserver.disconnect();
      }
    });
    aboutDrivehubBoxRef.current && aboutDrivehubObserver.observe(aboutDrivehubBoxRef.current);
  }, []);

  return (
    <div className="about-drivehub" ref={aboutDrivehubBoxRef}>
      <div className="container">
        <h2>ทำไมต้องเช่ารถผ่าน Drivehub</h2>
        <LazyLoad height={190}>
          <div className={`item-container ${toggle ? 'open' : ''}`}>
            <div className="item">
              <img src="/assets/shares/icon-1.svg" alt="ค้นหารถเช่าเพียงไม่กี่คลิก" />
              <h3>ค้นหารถเช่าเพียงไม่กี่คลิก</h3>
            </div>
            <div className="item">
              <img src="/assets/shares/icon-2.svg" alt="รับประกันราคาดีที่สุด" />
              <h3>รับประกันราคาดีที่สุด</h3>
            </div>
            <div className="item">
              <img src="/assets/shares/icon-3.svg" alt="จองฟรีไม่มีค่าธรรมเนียมใดๆ" />
              <h3>จองฟรีไม่มีค่าธรรมเนียมใดๆ</h3>
            </div>
            {/* end item */}
            <div className="item">
              <img src="/assets/shares/icon-4.svg" alt="มีหรือไม่มีบัตรเครดิตก็เช่าได้" />
              <h3>มีหรือไม่มีบัตรเครดิตก็เช่าได้</h3>
            </div>
            {/* end item */}
            <div className="item">
              <img src="/assets/shares/icon-5.svg" alt="ปลอดภัยตั้งแต่เริ่มจองจนสิ้นสุดการเช่า" />
              <h3>ปลอดภัยตั้งแต่เริ่มจองจนสิ้นสุดการเช่า</h3>
            </div>
            {/* end item */}
            <div className="item">
              <img src="/assets/shares/icon-6.svg" alt="มีเจ้าหน้าที่คอยให้บริการตลอดการเช่า" />
              <h3>มีเจ้าหน้าที่คอยให้บริการตลอดการเช่า</h3>
            </div>
            {/* end item */}
            <div className="item">
              <img src="/assets/shares/icon-7.svg" alt="คัดเลือกบริษัทรถเช่าที่ได้มาตรฐานเท่านั้น" />
              <h3>คัดเลือกบริษัทรถเช่าที่ได้มาตรฐานเท่านั้น</h3>
            </div>
            {/* end item */}
          </div>
          <div className="item-footer">
            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => handleOnClick()}>
              {toggle ? (
                <>
                  ย่อลง <i className="icon-up" />
                </>
              ) : (
                <>
                  ดูเพิ่มเติม <i className="icon-down" />
                </>
              )}
            </button>
          </div>
        </LazyLoad>
      </div>
    </div>
  );
};

export default ValueProps;
