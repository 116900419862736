import { FunctionComponent, PureComponent } from 'react';
import { StaticQuery, graphql } from 'gatsby';
// import { REGISTER_PROMOTION } from '@features';
import LazyLoad from 'react-lazyload';
import { Settings as SlickSettings } from 'react-slick';
import DhSlick from '@shares/dh-slick';
import { DhDots, DhPaging } from '@shares/dh-slick/custom-slick';
import LoadingBox from '@shares/loading';
import { PromotionCard } from './card';
import { PromotionsProps, PromotionMapResult, QueryResult, PromotionListProps } from './interface';
import { trackingPromotionArrow, trackingPromotionPaging, trackingPromotionSeeMore } from './tracking';
import './style.scss';

const PromotionList: FunctionComponent<PromotionListProps> = ({ prefix, cityID, isMobile }: PromotionListProps) => (
  <StaticQuery
    query={graphql`
      query {
        promotions: allMarkdownRemark(filter: { fields: { collection: { eq: "promotions" } } }) {
          edges {
            node {
              id
              frontmatter {
                path
                banner_promotion_path
                seo_title_th
                seo_title_en
                banner_position
                banner_src_th {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                banner_src_en {
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, formats: [AUTO, WEBP])
                  }
                }
                banner_show
                activate
                redirect_to_blog
                interbrand
                citypage
              }
            }
          }
        }
      }
    `}
    render={(data: QueryResult) => {
      let promotions = data.promotions.edges
        .map(({ node }) => node.frontmatter)
        .filter((p) => {
          if (p.activate === false) return;
          if (p.banner_show === false) return;
          return p;
        })
        .sort((a, b) => {
          return a.banner_position && b.banner_position && Number(a.banner_position) - Number(b.banner_position);
        })
        .map((item): PromotionMapResult => {
          return {
            interbrand: item.interbrand,
            imgSrc: item[`banner_src_${prefix}`],
            path: item.redirect_to_blog ? item.path : item.banner_promotion_path,
            citypage: item.citypage,
            seo_title_th: item.seo_title_th,
            promotionName: item.path,
            promotionTitle: item[`seo_title_${prefix}`],
            redirect_to_blog: !!item.redirect_to_blog
          };
        });

      if (cityID !== undefined) {
        promotions = promotions.filter((el) => {
          if (!el.citypage) return;
          if (el.citypage.includes('0')) return true;
          return el.citypage === cityID || el.citypage.includes(cityID);
        });
      }

      return <Promotions promotions={promotions} prefix={prefix} isMobile={isMobile} />;
    }}
  />
);
interface CustonDhDotPromotionProps {
  dots?: React.ReactNode;
  hideDot?: boolean;
  className?: string;
}
const CustonDhDotPromotion: FunctionComponent<CustonDhDotPromotionProps> = (props) => {
  const { dots, hideDot, className } = props;
  return <DhDots className={className}>{!hideDot && <ul>{dots}</ul>}</DhDots>;
};
interface PromotionsState {
  shouldReRender: boolean;
}

class Promotions extends PureComponent<PromotionsProps, PromotionsState> {
  state = {
    shouldReRender: false
  };
  componentDidUpdate(prevProps: PromotionsProps) {
    // TODO: Refactor
    if (prevProps.isMobile !== this.props.isMobile) {
      this.setState({ shouldReRender: true }, () =>
        setTimeout(() => {
          this.setState({ shouldReRender: false });
        }, 10)
      );
    }
  }
  render() {
    const { prefix, promotions } = this.props;

    const settings: SlickSettings = {
      dots: true,
      infinite: false,
      speed: 500,
      slidesToShow: 3,
      swipeToSlide: true,
      // eslint-disable-next-line react/display-name
      appendDots: (dots: React.ReactNode) => <CustonDhDotPromotion dots={dots} />,
      customPaging: (i) => <DhPaging i={i} handleTracking={(index: any) => trackingPromotionPaging(index + 1)} />,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            className: 'slider variable-width slider--dh-mobile',
            variableWidth: true,
            dots: true,
            // eslint-disable-next-line react/display-name
            appendDots: () => <CustonDhDotPromotion hideDot />,
            arrows: false,
            speed: 800,
            slidesToShow: 1,
            useTransform: false,
            useCSS: false
          }
        }
      ]
    };

    return (
      <>
        {promotions.length > 0 && (
          <section className="promotions">
            <div className="container position-relative">
              <h2 className="title">โปรโมชั่นและข่าวสารล่าสุด</h2>
              <LazyLoad height={220}>
                {this.state.shouldReRender ? (
                  <LoadingBox />
                ) : (
                  <div id="promotion-box">
                    <DhSlick
                      settings={settings}
                      isLoading={false}
                      sectionName="promotion"
                      onClickArrow={(e, direction) => {
                        trackingPromotionArrow(e, direction);
                      }}
                    >
                      {promotions.map((promo, index) => {
                        return (
                          <PromotionCard
                            {...promo}
                            prefix={prefix}
                            key={`${promo.path}-${index}`}
                            cardPosition={index.toString()}
                          />
                        );
                      })}
                    </DhSlick>
                    <a
                      className="see-all"
                      href="/th/promotions"
                      id="btn-promotion-see-all"
                      onClick={trackingPromotionSeeMore}
                    >
                      ดูโปรโมชั่นและข่าวสารทั้งหมด
                      <i className="icon-right" />
                    </a>
                  </div>
                )}
              </LazyLoad>
            </div>
          </section>
        )}
      </>
    );
  }
}

export default PromotionList;
