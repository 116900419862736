import { FunctionComponent } from 'react';
import './style.scss';

interface LoadingProps {
  className?: string;
}
export const Loading: FunctionComponent<LoadingProps> = ({ className }) => {
  return (
    <div className={`loading ${className ? className : ''}`}>
      <div className="container">
        <img src="/assets/shares/loading.svg" />
      </div>
    </div>
  );
};

export default Loading;
