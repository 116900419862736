import { PureComponent } from 'react';
import moment from 'moment';
import loadable from '@loadable/component';
import Background from '@shares/background';
import LoadingBox from '@shares/loading';
import { SectionSearchProps } from './interface';

const SearchBox = loadable(() => import('@shares/search-box'), {
  fallback: <LoadingBox />
});

export default class SectionSearch extends PureComponent<SectionSearchProps> {
  render() {
    const { backgroundSrc, children, valueProps, cityID, locationID, vendorID, prefix, sectionName, vendor, path } =
      this.props;
    return (
      <Background src={backgroundSrc} id="content-section-bg">
        <section className="banner-container" id="banner-box">
          <div className="overlay" />
          <div className="content-section" id="content-box">
            {children}
            <div className="search-box sample">
              <SearchBox
                bookingBegin={
                  vendorID
                    ? moment().add(7, 'days').format('YYYY-MM-DD 10:00')
                    : moment().add(1, 'days').format('YYYY-MM-DD 10:00')
                }
                bookingEnd={
                  vendorID
                    ? moment().add(9, 'days').format('YYYY-MM-DD 10:00')
                    : moment().add(3, 'days').format('YYYY-MM-DD 10:00')
                }
                locationID={locationID}
                cityID={cityID}
                prefix={prefix}
                vendorID={vendorID}
                sectionName={sectionName}
                vendor={vendor}
                isUseLocalApi={path && (path === 'true-leasing' || path === 'runway') ? true : false}
              />
            </div>
            <div className="contact-more">
              <p>สอบถามเพิ่มเติม</p>
              <ul className="contact-info list-inline">
                <li className="list-inline-item">
                  <a
                    // onClick={() => sendToGAByFixedText('contact_option', 'click', 'line')}
                    href="#"
                    className="lineat"
                  >
                    <img
                      src="/assets/shares/line-icon.svg"
                      alt="@drivehub"
                      style={{ margin: '0px 2px', width: '16px', height: '16px' }}
                    />{' '}
                    @drivehub
                  </a>
                </li>
                <li className="list-inline-item">
                  <a
                    // onClick={() => sendToGAByFixedText('contact_option', 'click', 'call')}
                    href="#"
                    className="call"
                  >
                    <i className="icon-phone"></i> 02-038-5222
                  </a>
                </li>
              </ul>
            </div>
            <div className="valueprop">
              <ul className="list-inline">
                {valueProps.map((el) => (
                  <li className="list-inline-item" key={el[`content_${prefix}`]}>
                    {el[`content_${prefix}`]}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
      </Background>
    );
  }
}
