import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

export const trackAboutDrivehubSeen = (sectionName: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(sectionName),
    event_action: 'seen',
    event_label: `why_choose_drivehub`
  });
};

export enum AboutDrivehubExpand {
  Expand = 'expand',
  Hide = 'hide'
}
export const trackAboutDrivehubExpand = (which: AboutDrivehubExpand, sectionName: PageSection) => {
  sendToDataLayer(undefined, {
    event_category: getDefaultEventCategory(sectionName),
    event_action: `${which}`,
    event_label: `why_choose_drivehub`
  });
};
