import { sendToDataLayer, getDefaultEventCategory, PageSection } from '@utils';

const PROMOTION_TRACK_CATEGORY = 'promotion_section';

export const trackPromotionCard = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, promotionName: string) => {
  sendToDataLayer(e, {
    event_category: PROMOTION_TRACK_CATEGORY,
    event_action: 'click_card',
    event_label: `${promotionName}`
  });
};
interface PromotionDetailEcom {
  promotionID: string;
  promotionName: string;
  position: string;
  promotionTitle: string;
}

export const trackCarDealCardImpressionEcom = (promotionDetail: PromotionDetailEcom) => {
  const ecommerceData = {
    promoView: {
      promotions: [
        // Array of promoFieldObjects.
        {
          id: `${promotionDetail.promotionID}`, // For Example is medical_01
          name: `${promotionDetail.promotionName}`, // For Example is medical_discount250thb.
          creative: `${getDefaultEventCategory(PageSection.Home)}_slide_banner`, // For Example homepage_promotion_slide_banner
          position: `${promotionDetail.position}` // For Example is slot_01
        }
      ]
    }
  };
  sendToDataLayer(undefined, {
    event: 'ecommerce_promotionImpression',
    event_category: 'ecommerce',
    event_action: 'promotion_impression',
    event_label: `${promotionDetail.promotionTitle}`,
    ecommerce: ecommerceData
  });
};

export const trackCarDealCardClickEcom = (promotionDetail: PromotionDetailEcom) => {
  const ecommerceData = {
    promoClick: {
      promotions: [
        // Array of promoFieldObjects.
        {
          id: `${promotionDetail.promotionID}`, // For Example is medical_01
          name: `${promotionDetail.promotionName}`, // For Example is medical_discount250thb.
          creative: `${getDefaultEventCategory(PageSection.Home)}_slide_banner`, // For Example homepage_promotion_slide_banner
          position: `${promotionDetail.position}` // For Example is slot_01
        }
      ]
    }
  };
  sendToDataLayer(undefined, {
    event: 'ecommerce_promotionClick',
    event_category: 'ecommerce',
    event_action: 'promotion_click',
    event_label: `${promotionDetail.promotionTitle}`,
    ecommerce: ecommerceData
  });
};

export enum ArrowDirection {
  Next = 'arrow_right',
  Prev = 'arrow_left'
}
const getArrowTrackDirection = (d: string) => {
  switch (d) {
    case 'next':
      return ArrowDirection.Next;
    case 'prev':
      return ArrowDirection.Prev;
    default:
      return '';
  }
};
export const trackingPromotionArrow = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>, direction: string) => {
  sendToDataLayer(e, {
    event_category: PROMOTION_TRACK_CATEGORY,
    event_label: `${getArrowTrackDirection(direction)}`
  });
};

export const trackingPromotionPaging = (index: number) => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_TRACK_CATEGORY,
    event_label: `pagination_${index}`
  });
};

export const trackingPromotionSeeMore = () => {
  sendToDataLayer(undefined, {
    event_category: PROMOTION_TRACK_CATEGORY,
    event_label: `more_promotion`
  });
};
